#screenshot-icon {
    color: white;
    position: fixed;
    bottom: 40px;
    left: 30px;
    z-index: 10000;
    background-color: $theme-dark;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .fa-camera {
        font-size: 20px;
        color: $white;
    }

    .description {
        color: $white;
        width: 120px;
        font-size: 11px;
        padding-left: 5px;
    }
}

#screenshot-icon:hover {
    .fa-camera, .description {
        color: $light-blue;        
    }
}

.shown {
    opacity: 0.7;
    transition: all 250ms linear;
}

.hidden {
    opacity: 0;
    transition: all 250ms linear;
}