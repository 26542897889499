.react-datepicker {
    &-wrapper{
        width: 100%;
    }
    &__input-container {
        width: 100%;
        input {
            font-size: 1rem;
            width: 100%;
            color: #495057;
        }
    }
}