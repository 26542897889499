.faq-container{
    height:100%;
    margin:0 100px;

    h1{
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        background:#85BEF6;
        text-align: center;
        color:white;
        min-height: 100px;
        margin-bottom:20px;
    }
    .content-container{
        .item{
            .title{
                font-size:16px;
                background: #FBF8F9;
                padding:8px 0;
                font-weight: bold;
                .question{
                    color:red;
                    margin-right:5px;
                }
            }
            .text{
                font-size:16px;
                .answer{
                    color:#4788F4;
                    margin-right:5px;
                }
                
            }
        }
    }
    @media only screen and (max-width: 465px) {
        margin:0 2px;

    }
    @media only screen and (max-width: 321px) {
        margin:0 2px;

    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    }
}