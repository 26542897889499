.home-container {
    .stats-info {
        background: #fafafa;
        color: #606060;
        padding: 13px 150px 13px 150px;
        min-height: 200px;
        .accolade {
            padding: 15px;
            .icon-header {
                text-align: center;
            }
            .accolade-header {
                font-weight: bold;
                margin-top: 10px;
            }
            i {
                font-size: 3em;
                color: #447fff;
            }
            p {
                font-weight: 400;
            }
        }
    }

    .hero {
        max-width: 1024px;
        margin: 0 auto;

        .learn-more-container{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            h2{
                flex:1;
                font-size:2em;
                text-align: center;
                margin-bottom:20px;
            }
            .text{
                flex:3;
                text-align:center;
                margin-bottom:40px;
            }
            .btn {
                padding: 20px 0;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 30px;
                background-color: #447fff;
                letter-spacing: 1px;
                text-align: center;
                font-size: 16px;
                margin-right: 0;
                width:70%;
            }
        }
        .location {
            font-weight: 500;
        }
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
       
        .with-logo {
            background-image: url("../img/stethoscope.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
        .logo-mobile-only {
            display: none;
        }
    }
    .info {
        max-width: 1024px;
        margin: 0 auto;
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
        .btn {
            padding: 20px;
            width: 40%;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 30px;
            background-color: #447fff;
            letter-spacing: 1px;
            text-align: center;
            font-size: 16px;
            margin-right: 0;
        }
        .with-logo {
            background-image: url("../img/heartbeat.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
    }
    .steps {
        // max-width: 1024px;
        background-color: #77a2ff;
        color: #eee;
        text-align: center;
        text-align: -webkit-center;
        margin: 0 auto;
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
        .btn {
            padding: 20px;
            width: 40%;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 30px;
            background-color: #447fff;
            letter-spacing: 1px;
            text-align: center;
            font-size: 16px;
            margin-right: 0;
        }
        .with-logo {
            background-image: url("../img/heartbeat.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
    }
    &--office {
        background-color: $white;
    }
    @media only screen and (max-width: 465px) {
        .stats-info {
            padding: 13px 50px 13px 50px;
        }
        .hero {
            .btn {
                width: 60%;            
            }

            .row {
                 display: block;
                // flex-direction: column;
                // align-items: center;
            }

            .learn-more-container {
                text-align: center;
                margin-bottom:30px;
                h2{
                    font-size:20px;
                    font-weight:bold;
                    text-align: center;

                }
                .lead{
                    font-size:14px;
                    text-align: center;
                }
                .btn {
                    width: 30%;
                    height:50%;
                    font-size:14px; 
                    padding:5px 0; 
                    display: inline-block;            
                }
            }
            
            
            .logo-mobile-only {
                position: absolute;
                top: 155px;
                right: 35px;
                display: block;
            }
            .with-logo {
                background-image: none !important;
            }
            .hero-header {
                word-spacing: 9999999px;
            }
        }
    }
    @media only screen and (max-width: 321px) {
        .hero {
            .row {
                 display: block;
                // flex-direction: column;
               
            }

            .learn-more-container {
                text-align: center;
                margin-bottom:30px;
                h2{
                    font-size:20px;
                    font-weight:bold;
                    text-align: center;

                }
                .lead{
                    font-size:14px;
                    text-align: center;
                }
                .btn {
                    width: 30%;
                    height:50%;
                    font-size:14px; 
                    padding:5px 0;   
                    display: inline-block;         
                }
            }
           

           
            .logo-mobile-only {
                position: absolute;
                top: 170px;
                right: -60px;
                display: block;
            }
        }
    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .hero {
            // padding-left: 45px;
        }
    }
}