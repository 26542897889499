.cover-page {
    margin: auto;
    width: 650px;
    margin-top: 10px;
    border: 1px solid black;
    padding: 25px;
    font-size: 20px;
    color: black;
  
    p {
        margin-bottom: 0;
    }

    strong {
        font-size: inherit;
        color: black;
    }

        .textContainer {
            font-size: 25px;
            .pharmacy-info {
                font-style: italic;
            }
            .title {
                margin-bottom: 0;
                &__text {
                    text-decoration: underline;
                }
            }
            .text {
                margin-bottom: 0;
                &--strong {
                    font-size: inherit;
                    font-weight: 800;
                }
            }
            .webname {
                letter-spacing: 3px;
                font-size:50px;
                font-weight:bold;
            }
    }

    .row {
        margin-top: 10px;
        .website {
            font-size: 50px;
        }
        .text-request {
            font-size: 22px;
            line-height: 24px;
            strong {
                font-size: inherit;
            }
        }
    
        .ct {
            border:1px solid black;
            font-size:28px;
            font-weight:bold;
            padding:20px;
            text-align:center;
            margin-top: 25px;
        }
        .words-error {
            margin-top: 20px;
            font-size: 20px
        }

        .rx-logo {
            width: 150px;
            height: 120px;
            margin-left: 20%;
            margin-top: 20px;
        }    
            
        .text-location {
            position: absolute;
            left: 60px;
            top: 45px;
            font-size: 23px;
            font-weight: bold;
            text-shadow: 1px 1px #000000;
        }

        .text-service {
            font-weight: bold;
            padding-left: 20px;
            // font-size: 15px;
            padding-top: 10px;      
        }
        .contact {
            margin-top: 20px;
        }
    }
}


#prescription-form {
    margin: auto;
    width: 650px;
    margin-top: 10px;
    border: 1px solid black;

    .header {     
        align-items: center;

        .message {
            padding: 5px 10px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }

        .banner {
            border-top: 1px solid black;
            display: flex;
            
            .logo {
                border: 1px solid black;
                border-width: 0 1px 0 0;

                img {
                    width: 158px;
                    height: 111px;
                    margin: 3px;
                }
            }
            
            .clinic {
                padding: 5px 10px;
    
                .clinic-owner {
                    font-size: 14px;
                    font-weight: bold;
                }
    
                .clinic-name {
                    font-size: 20px;
                    font-weight: bold;
    
                    span {
                        font-style: italic;
                    }
                }
    
                .clinic-address {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .content {
        border-top: 1px solid black;

        .today {
            display: flex; 
            justify-content: flex-end;
            padding: 5px 10px;
            font-weight: bold;
        }

        .profile {
            display: flex;
            justify-content: space-between;

            .person {
                padding: 5px 10px;
    
                .healthInfo {
                    font-weight: bold;
                }
            }

            .allergy {
                padding: 5px 10px;
                width: 350px;

                ul {
                    margin-bottom: 10px;
                    padding-left: 1.2em;
                    font-size: 13px;
                }
            }
        }

        .medications {
            .medication {
                border-top: 1px solid black;
                margin-bottom: 0px;
        
                .badge-medication {
                    padding: 10px;            
                }
            }
        }

        .doctorNote {
            padding:10px;
            margin:0;
            border-top:1px solid black;
                label {
                    display:block;
                    font-size: 15px;
                    font-weight: 700;
                }
                textarea {
                    width:100%;
                    height:65px;
                }
        }
        
        .signature {
            padding: 30px;
            text-align: center;
            // padding-top: 35px;
            border-top: 1px solid black;

            div {
                font-weight: bold;
                font-size: 20px;
            }

            .signature-wrapper {
                display:flex;
            }

            .signature-img {
                width: 200px;
                height:50px;
                padding-left:20px;
            }

            .sigCanvas {
                border: 1px solid black;
                width:400px;
                height:100px;

            }

            .underline{
                border-top: 1px solid black;
                display: inline-block;
                width: 65%;
            }

            .formal-sign {
                font-size: 14px;
            }
        }

        .pharmacy {
            padding: 10px;
            border-top: 1px solid black;

            div {
                text-align: center;
            }                
        }
    }
}

@media print {
    .no-print {
        display: none !important;
    }

    .prescription {
        width: 80%;
    }
}
