.text-container {
    display:flex;
    flex-direction: column;
    height:100%;
    margin:0 100px;

    h1 {
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        background:#85BEF6;
        text-align: center;
        color:white;
        min-height: 150px;
        margin-bottom:20px;
    }
    .text{
        padding:20px 75px;
        text-align:center;
        font-size:20px;
        min-height:250px;
        
    }
    .contact-container{
        padding:20px 20px;
        text-align: center;
        background: #F8FAFB;
        width:100%;
        min-height: 200px;
        h4{
            margin-bottom: 15px;
        }
    }
    @media only screen and (max-width: 465px) {
        margin:0;
        .text{
            padding:0;
        }
    }
    @media only screen and (max-width: 321px) {
        margin:0;
        .text{
            padding:0;
        }
    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    }
}