.format-phone-number {
    .format-bracket {
        font-size:25px;
        margin: 0 1px;
    }
    .dash-line {
       font-size: 25px;
       margin: 0 1px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .form-control {
        width: 20%;
    }
}