.profile-page {
    .confirm-error-div {
        display: flex;
        align-items: flex-end;

        label {
            color: red;
        }
    }
}
